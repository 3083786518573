.karta{
   width: 100%; 
    /*height: 100%;background-image: url(../../../assets/images/karta.jpg);
    background-position: center;
    background-repeat: no-repeat;
     background-size: cover;*/
    width: 3840px;
    height: 2160px;
    position: relative;
} 

.karta h1{
    font-size: 7vw;
    color: #fff;
    position: absolute;
    top: 5vh;
    right: 5vw;
    text-align: right;
}
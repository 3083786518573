.prozirni_box{
    width:/*1016px*/100%; /*1108*/
    height: /*544px*/100%;
    background: rgba(255,255,255,.4);
    padding: /*45px;*/5%;
    border-radius: /*90px*/15%; border: 1px solid #ffffff;
    float: left;
    overflow: hidden;
    margin-left: 1.3%;
    z-index: 20;
}

.kontent_box{
    width:/*1016px*/100%; /*1108*/
    height: /*544px*/100%;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: /*60px*/15%;
    position: relative;
    overflow: hidden;
}

.thumb{
   /* width:1016px; height: 544px;*/
   width:/*1016px*/100%;
}
.naslov{
    font-size: 400%;
    line-height: 100%;
    color: #ffffff;
    position: absolute;
    bottom: 10%; left: 5%;
    text-shadow: 2px 2px #00000075;
}

.modal-content{
background: #fff !important;
}
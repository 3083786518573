.modal-content{
  background: none !important;
  /*border: none !important;*/
}

.ne_prozirno .modal-content {
  background: #000 !important;
}

.bijelo{
  color:#fff;
}
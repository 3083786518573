.tocka_holder{
  position: absolute; 
  
}
.tocka{
    background: #00c6b9;
    width: 60px; height: 60px;
    line-height: 50px; text-align: center;
    color: #fff; font-size: 28px;
    position: relative;
    border:5px solid #fff;    
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
}

.crta-desno{
  position: absolute; 
  right: -25px;
  bottom: 0px;
  height: 50px;
  /*border-bottom: 2px solid rgb(255, 255, 255);*/
  border-right: 2px solid #fff;
  -moz-transform: skew(-45deg);
  -webkit-transform: skew(-45deg);
  transform: skew(-45deg);
}

.tocka_tekst{
  color: #fff; font-size: 24px;
  position: relative;
  padding: 0 15px 0 15px;
  width: max-content;
  border-bottom: 1px solid #fff;
}

.lbl_crta{
  top: 51px;
  right: 74px;
  position: absolute;
  /*padding: 0 110px 0 110px;*/
}

.modal-content{
  background-color: none !important;
  border: none !important;
}





.tocka_1{
  background: url(../../../assets/images/l1.png); width:414px; height:157px; position:relative;
}
.tocka_1 div{
  position: absolute;
  width: 260px;
  text-align: center;
  font-size: 40px; line-height: 44px;
  color: #fff;
  padding: 10px 0 10px 0;
  bottom: 170px; left:0;
}
.tocka_1 span{
  position: absolute;
  font-size: 48px;
  color: #fff;
  bottom: 0px; right: 0px;
  padding: 12px;
  width: 95px;
  text-align: center;
}


.tocka_2{
  background: url(../../../assets/images/l2.png); width:414px; height:157px; position:relative;
}
.tocka_2 div{
  position: absolute;
  width: 260px;
  text-align: center;
  font-size: 40px; line-height: 44px;
  color: #fff;
  padding: 10px 0 10px 0;
  bottom: 170px; right: 0;
}
.tocka_2 span{
  position: absolute;
  font-size: 48px;
  color: #fff;
  bottom: 0px; left: 0px;
  padding: 12px;
  width: 95px;
  text-align: center;
}

.tocka_3{
  background: url(../../../assets/images/l3.png); width:414px; height:157px; position:relative;
}
.tocka_3 div{
  position: absolute;
  width: 260px;
  text-align: center;
  font-size: 40px; line-height: 44px;
  color: #fff;
  padding: 10px 0 10px 0;
  bottom: 10px; right: 0;
}
.tocka_3 span{
  position: absolute;
  font-size: 48px;
  color: #fff;
  top: 0px; left: 0px;
  padding: 12px;
  width: 95px;
  text-align: center;
}

.tocka_4{
  background: url(../../../assets/images/l4.png); width:414px; height:157px; position:relative;
}
.tocka_4 div{
  position: absolute;
  width: 260px;
  text-align: center;
  font-size: 40px; line-height: 44px;
  color: #fff;
  padding: 10px 0 10px 0;
  bottom: 10px; left: 0;
}
.tocka_4 span{
  position: absolute;
  font-size: 48px;
  color: #fff;
  top: 0px; right: 0px;
  padding: 12px;
  width: 95px;
  text-align: center;
}
/*
.header-image figure {
    -webkit-mask-image: linear-gradient(90deg, #0000, #fff, #0000);
            mask-image: linear-gradient(90deg, #0000, #fff, #0000);
    -webkit-mask-size: 300% 100%;
            mask-size: 300% 100%;
    animation: clip-fade 3s infinite alternate;
  }
  
  img {
    max-width: 100%;
  }
  
  @keyframes clip-fade {
    100% {
      -webkit-mask-position: right;
              mask-position: right;
    }
  }
  
  body {
    background: red;
  }
  */
.h1{
    font-size: 5rem;
    color: #00599d;
}
.h2{
    font-size: 3rem;
    color: #00599d;
    /*width: 40% !important;*/
}

.prozirni_rub{
    border-radius: 50%;
    width: 600px; height: 600px; padding: 30px;
    /*width: 980px; height: 980px; padding: 40px;*/
    font-size: 1.8rem;
    color: #00599d;
    text-align: center;
    background: rgba(255, 255, 255, 0.4);
}
.krug {
    width: 540px; height: 540px; font-size: 1.7rem;
    /*width: 900px; height: 900px; font-size: 2.7rem;*/
    border-radius: 50%;
    padding: 75px;    
    color: #00599d;
    text-align: center;
    background: #fff;
}

.naslov{
    padding: 30px !important;
    background: #ffffff85;
    border-radius: 30px;
    width: 93% !important;
}
.naslov2{
    padding: 30px;
    background: #ffffff85;
    border-radius: 30px;
}